import React, { useState,useEffect } from 'react'
import './Testimonial.css'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Pagination } from 'swiper/modules';



const Testimonial = () => {
    const [slidesPerView, setSlidesPerView] = useState('2');
    const handleResize = () => {
        const windowWidth = window.innerWidth;
    
        // Adjust slidesPerView based on window width
        if (windowWidth < 400) {
          setSlidesPerView(1);
        } else if (windowWidth >= 400 && windowWidth < 768) {
          setSlidesPerView(2);
        } else {
          setSlidesPerView(2);
        }
      };
    // useEffect(() => {
    //     handleResize()
    //     Aos.init()
    // }, [])
    
  return (
    <div className='testimonial-container'>
        <div className="welcome text-center">
          <h2>
           What Our<span> Client Says</span>
          </h2>
          <p>
          Discover the transformative power of Ayurvedic wisdom with SLNG Organic ProductsA testament to holistic wellness and purity.
          </p>
        </div>
        <div className='testimonial'>
        <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
          }}
        modules={[FreeMode, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
            <div className='client'>
                <div className='client-img'>
                    <img src="https://cdn.stumagz.com/images/5766bbdc415f1stryimg" alt="img" />
                </div>
                <div className='client-text'>
                    <p>
                    SLNG Organic Productsffers pure, potent Ayurvedic products that are truly transformative. Their authenticity and efficacy make them my go-to for holistic wellness. Highly recommend for natural well-being.</p>
                    <h2>Shakti Solanki</h2>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='client'>
                <div className='client-img'>
                    <img src="https://img.freepik.com/free-photo/photo-attractive-bearded-young-man-with-cherful-expression-makes-okay-gesture-with-both-hands-likes-something-dressed-red-casual-t-shirt-poses-against-white-wall-gestures-indoor_273609-16239.jpg?size=626&ext=jpg&uid=R134274668&ga=GA1.1.229895539.1703719784&semt=sph" alt="img" />
                </div>
                <div className='client-text'>
                    <p>
                    Experience the transformative power of SLNG Organic Products pure Ayurvedic products. With unmatched authenticity and efficacy, they're my top choice for holistic wellness. Highly recommended for those seeking natural well-being.</p>
                    <h2>Anuj Sharma</h2>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='client'>
                <div className='client-img'>
                    <img src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cmFuZG9tJTIwcGVyc29ufGVufDB8fDB8fHww" alt="img" />
                </div>
                <div className='client-text'>
                    <p>
                    Discover the profound impact of SLNG Organic Products authentic Ayurvedic products. With unrivaled purity and effectiveness, they're my ultimate destination for holistic well-being. Highly recommended for those embracing natural health.</p>
                    <h2>Shyam Jain</h2>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='client'>
                <div className='client-img'>
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTbuz2Zu_hCvDUw98WGFSQIpWX5UcRdySBqQ&usqp=CAU" alt="img" />
                </div>
                <div className='client-text'>
                    <p>
                    Explore the unmatched benefits of SLNG Organic Products genuine Ayurvedic offerings. With unparalleled purity and efficacy, they stand as my premier choice for holistic wellness. Highly recommended for those prioritizing natural health solutions.</p>
                    <h2>Anish Varma</h2>
                </div>
            </div>
        </SwiperSlide>
      </Swiper>
        </div>
    </div>
  )
}

export default Testimonial