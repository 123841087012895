
import './App.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Header from './Comonente/Header/Header';
import Home from './Comonente/Home/Home'
import About from './Comonente/About/About';
import Product from './Comonente/Product/Product';
import Footer from './Comonente/Footer/Footer';
import Testimonial from './Comonente/Testimonial/Testimonial';
import Contact from './Comonente/Contact/Contact';
import Ayurvedic from './Comonente/Ayurvedic/Ayurvedic';
import SuperFood from './Comonente/SuperFood/SuperFood';

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/product' element={<Product/>}/>
          <Route path='/testimonial' element={<Testimonial/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/ayurvedic' element={<Ayurvedic/>}/>
          <Route path='/superfood' element={<SuperFood/>}/>

        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
