import React from 'react'
import './Hero.css'
import banner from './Banner (1).png'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Hero = () => {
  return (
    <div className='slider'>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {/* <SwiperSlide>
          <img  src="https://i.ibb.co/FHzzbL9/SLNG-Banner.png" alt="img" />
          <div className='banner-detail'>
              <h2>SLNG Organic Products</h2>
              <p>
              Ayurvedic products meticulously crafted from the purest
              ingredients sourced from our own cowshed.
              </p>
              <p>
                <input type="submit" className="submit" value="submit" />
              </p>
          </div>
        </SwiperSlide> */}
        <SwiperSlide>
          <img  src={banner} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://static.vecteezy.com/system/resources/previews/002/234/366/large_2x/group-of-cows-eat-the-grass-in-the-large-field-with-cityscape-background-free-photo.jpg" alt="img" />
          <div className='banner-detail'>
              <h2>SLNG Organic Products</h2>
              <p>
              Ayurvedic products meticulously crafted from the purest
              ingredients sourced from our own cowshed.
              </p>
              <p>
                <input type="submit" className="submit" value="submit" />
              </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://i.ytimg.com/vi/ZwdyMufH--4/maxresdefault.jpg?sqp=-oaymwEmCIAKENAF8quKqQMa8AEB-AHUBoAC4AOKAgwIABABGE0gZSg0MA8=&rs=AOn4CLB6RniUYQy-kIUQpEJYBkFh9QwlEQ" alt="img" />
          <div className='banner-detail'>
              <h2>SLNG Organic Products</h2>
              <p>
              Ayurvedic products meticulously crafted from the purest
              ingredients sourced from our own cowshed.
              </p>
              <p>
                <input type="submit" className="submit" value="submit" />
              </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Hero