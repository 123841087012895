import React from "react";
import "./Contact.css";
const Contact = () => {
  return (
    <div className="contact-container w-full h-full">
      <div className="contact ">
        <div className="contact-left">
          <div className="head-drop">
            <h2>Drop us message for any Query</h2>
          </div>
          <div className="form">
            <form>
              <p className="input-flex">
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name*"
                  required
                  className="form-style"
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email*"
                  required
                  className="form-style"
                />
              </p>

              <p>
                <input
                  type="number"
                  name="number"
                  placeholder="Your Number*"
                  required
                  className="form-style"
                />
              </p>

              <p>
                <textarea
                  name="message"
                  cols="30"
                  rows="6"
                  minlength="5"
                  placeholder="Your Message*"
                  required
                ></textarea>
              </p>
              <p>
                <input type="checkbox" id="checkbox" name="checkbox" checked />{" "}
                Yes, I would like to receive communications by call / email
                about Company's services.
              </p>
              <p>
                <input type="submit" className="submit" value="submit" />
              </p>
            </form>
          </div>
        </div>
        <div className="contact-right">
          <div className="detail">
            <ul>
              <li>
              <i class="ri-map-pin-fill"></i>
              <p>Maikva Khera,Majra Teekar Gadhi (Near Awas Vikas Colony) UNNAO -U.P.(India)-209801</p>
              </li>
              <li>
              <i class="ri-phone-fill"></i>
              <p>Pradeep Jha: +91 9958969632  <br /> Omshankar Tripathi: +91 9628768888 <br/>Abhimanyu Yadav: +91 9126959551 <br/>Brajmohan Jyotish: +91 7985846911 </p>
              </li>
              <li>
              <i class="ri-mail-fill"></i>
              <p>info@slng.com</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
