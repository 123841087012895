import React from 'react'

const Ayurvedic = () => {
  return (
    <div className='product-container w-full h-full flex flex-col items-center justify-center'>
      <div className="welcome text-center">
          <h2>
            Ayurvedic<span> Medicine</span>
          </h2>
          <p>
          Elevate your well-being with our pure, Ayurvedic treasures, meticulously crafted for holistic harmony.
          </p>
        </div>
        <div className='product-section w-full h-full'>
            <div className='products w-full h-full'>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://earthroot.in/wp-content/uploads/2019/06/safed-musli-root.jpg" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Safed Musli</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://d1f34ajap1v5tm.cloudfront.net/image/Powerful-Health-Benefits-Of-Ashwagandha.jpg" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Ashwagandha</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://manmatters.com/blog/content/images/2022/03/Untitled-design---2022-03-10T185704.901_11zon.jpeg" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Shatavari</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://rukminim2.flixcart.com/image/850/1000/juoes280/plant-seed/p/t/d/50-salam198181-radhakrishna-original-imaffqfjjchzza3y.jpeg?q=90&crop=false" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>salam mishri</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://www.1mg.com/hi/patanjali/wp-content/uploads/2019/02/Baheda3.png" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Bhera Amla</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://m.media-amazon.com/images/I/61vCJ2f547L._AC_UF1000,1000_QL80_.jpg" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Kala Jeera</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3tqmP9L_g7ZSXkqSSfG21tOkUuof42bU58yq69qhjFA&s" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Vidari</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://images.onlymyhealth.com/imported/images/2022/May/18_May_2022/vihari-kand-main.jpg" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Vidari Kand</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://5.imimg.com/data5/SELLER/Default/2022/12/YE/EV/WE/86539219/kalonji-seeds.jpg" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Kalonji Seeds</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://m.media-amazon.com/images/I/611VtIEQxrL.jpg" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Mishri Arjun</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://www.jiomart.com/images/product/original/rvqdbpjaj1/yuvika-kapoor-kachri-kapur-kachri-ekangi-hedychium-spicatum-spiked-ginger-lily-800-grams-product-images-orvqdbpjaj1-p591464113-1-202205191805.jpg?im=Resize=(420,420)" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Kapoor Kachri</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://m.media-amazon.com/images/I/613bh0aw6qL._AC_UF1000,1000_QL80_.jpg" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Shikakai</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://5.imimg.com/data5/SELLER/Default/2023/2/QT/IM/DU/72303319/gudman-extrect-powder2-500x500.webp" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Gudman</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://thewholesaler.in/cdn/shop/products/thewholesaler_price_ChiraitaAsli.jpg?v=1641967235" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Chiraita</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://www.stylecraze.com/wp-content/uploads/2019/11/Giloy-Benefits-and-Side-Effects-in-Hindi-1.jpg" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Giloy</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://5.imimg.com/data5/PP/CC/RP/SELLER-1354677/neem-powder.jpg" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Neem Powder</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Ayurvedic