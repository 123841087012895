import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Header.css'

const Header = () => {
    const[isopenMenu,setopenMenu] = useState(false)
    const[isMenuVisible, setMenuVisible] = useState(false)

    const onhandleChange = ()=>{
        setopenMenu(!isopenMenu)
    }

    const closeMenu =()=>{
        setopenMenu(false)
    }

    const MouseEnter = ()=>{
        setMenuVisible(true)
    }
    const MouseLeave = ()=>{
        setMenuVisible(false)
    }
  return (
    <div className='main-container w-full h-full py-5 px-5 bg-[#090909]'>
        <header className='flex items-center justify-between'>
            <div className='logo'>
                {/* <img src="" alt="" /> */}
                <h2 className='text-3xl text-white font-bold '>SLNG</h2>
            </div>

            <nav className='navbar'>
                <ul className={`nav-links flex items-center justify-center gap-20 ${isopenMenu ? 'click' : ''}`}>
                    <li onClick={closeMenu} className='text-xl text-white font-semibold'><Link to='/'>Home</Link></li>
                    <li onClick={closeMenu} className='text-xl text-white font-semibold'><Link to='/about'>About Us</Link></li>

                    <li onMouseLeave={MouseLeave} className='slng-dropdown text-xl text-white font-semibold'>
                        <Link className='hover dropdown-hover' onMouseEnter={MouseEnter} >Product</Link>
                        <ul className={`dropdown  ${isMenuVisible ? 'visible' : 'hidden'}`} >
                            <li><Link target='blank'  onClick={closeMenu} to='/ayurvedic'>Ayurvedic Medicine</Link></li>
                            <li><Link target='blank'  onClick={closeMenu} to='/superfood'>Super Food</Link></li>
                        </ul>
                    </li>

                    <li onClick={closeMenu} className='text-xl text-white font-semibold'><Link to='/testimonial'>Testimonial</Link></li>
                    <li onClick={closeMenu} className='text-xl text-white font-semibold'><Link to='/contact'>Contact Us</Link></li>
                </ul>
            </nav>
            <div className='responsive'> 
            <i onClick={onhandleChange} className="ri-menu-3-line text-white text-xl font-bold"></i>
            </div>
        </header>
    </div>
 )
}

export default Header