import React from 'react'
import './product.css'

const Product = () => {
  return (
    <div className='product-container w-full h-full flex flex-col items-center justify-center'>
      <div className="welcome text-center">
          <h2>
            Our<span> Product</span>
          </h2>
          <p>
          Elevate your well-being with our pure, Ayurvedic treasures, meticulously crafted for holistic harmony.
          </p>
        </div>
        <div className='product-section w-full h-full'>
            <div className='products w-full h-full'>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://img.freepik.com/premium-photo/colorful-different-spices_752237-13041.jpg?w=740" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Spices</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://img.freepik.com/free-photo/jar-with-fresh-honey_144627-17145.jpg?t=st=1710411452~exp=1710415052~hmac=5555f0c37cb7e69ca1ca4c9bd6dc320cb3a2cd3112074723db244acfd5a22bab&w=740" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Honey</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://i.ibb.co/ZMxL1LV/image.png" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>toothpaste</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://img.freepik.com/free-photo/sponge-various-soaps_23-2148796388.jpg?t=st=1710412022~exp=1710415622~hmac=ed577477badde37fad9149f9bce58c7becf8fe8c4ef992f07dc32d1c220e159b&w=740" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Soap</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://img.freepik.com/free-photo/female-farmer-milking-cow-farm_114579-2831.jpg?t=st=1710412245~exp=1710415845~hmac=a3e34550062162d208ca88d017fad3cc3b85d61c8e18de5aac31e90bbd57f755&w=740" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Milk</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://img.freepik.com/free-photo/fresh-flavorful-mashed-potatoes_2829-11563.jpg?t=st=1710412344~exp=1710415944~hmac=853410ba5b38b228ec75a07db7a8877f99ca247a7a6a5af079ad507b93860edc&w=740" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Ghee</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://5.imimg.com/data5/NL/XY/IT/SELLER-37845155/cow-dung-wood.jpg" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Dung Wood</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://img.freepik.com/free-photo/arrangement-natural-various-herbs-high-view_23-2148791497.jpg?t=st=1710412985~exp=1710416585~hmac=367ecfd830e2bd74ce3fb6988090abfea09319b90f21061c5db4abff7b97701e&w=740" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Ayurvedic Medicine</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://m.media-amazon.com/images/I/81ME-rI-kxL._AC_UF350,350_QL80_.jpg" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Gomutra</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://images.herzindagi.info/image/2019/May/moringa-hair-skin.jpg" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Moringa Powder</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://www.shudhonline.com/cdn/shop/products/ArjunaBark_1_1999x.jpg?v=1633270772" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Arjuna Powder</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Product