import React from "react";
import Hero from "../Hero/Hero";
import About from "../About/About";
import Product from "../Product/Product";
import Testimonial from "../Testimonial/Testimonial";
import Contact from "../Contact/Contact";

const Home = () => {
  return (
    <>
      <Hero />
      <About />
      <Product/>
      <Testimonial/>
      <Contact/>
    </>
  );
};

export default Home;
