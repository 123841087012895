import React from "react";
import "./About.css";

const About = () => {
  return (
    <div className="about-container w-full h-full bg-[#edecec]">
      <div className="about-section flex flex-col items-center justify-center w-full p-20  gap-10">
        <div className="welcome text-center">
          <h2>
            Welcome <span>To SLNG</span>
          </h2>
          <p>
            Welcome to the SLNG grounds of our Agriculture Product Selling
            website Explore our diverse range of premium-quality agricultural
            products tailored to elevate your cultivate success.
          </p>
        </div>

        <div className="about">
          <div className="right">
            <h2>About Us</h2>
            <p>
              At SLNG Organic Products, we are dedicated to crafting Ayurvedic
              treasures of the highest quality, meticulously sourced from our
              own esteemed cowshed. Our unwavering commitment to
              Ayurveda'sancient wisdom drives us to create products that not
              only nourish the body but also harmonize the mind and spirit,
              fostering overallbalance and vitality. What truly sets us apart is
              our unwavering dedication to authenticity and quality. Each of our
              offerings, derived from time-honored formulations, undergoes
              rigorous supervision to ensure utmost efficacy and purity. We
              firmly believe in responsibly harnessing the power of nature in
              every item we offer, staying true to our philosophy deeply rooted
              in our connection to the sacred cow, revered in Ayurveda for its
              purity, abundance, and nurturing energy. Understanding that the
              well-being of our cows directly influences the quality of our
              products, we prioritize their care and welfare. Whether you seek
              herbal supplements, skincare essentials, or wellness teas, SLNG
              Organic Products invites you to embark on a journey towards
              holistic well-being, guided by tradition and nature's wisdom.
            </p>

            <div>
              <button className="btn">Read More</button>
            </div>
          </div>
          <div className="left">
            <div className="image">
              <img
                src="https://img.freepik.com/free-photo/child-hands-holding-caring-young-green-plant_1150-12737.jpg?t=st=1710397714~exp=1710401314~hmac=d21f6f0ed97611c3871ccb616d2fc7e3ae02dd18c0d45342d2fadd80356d6e49&w=740"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
