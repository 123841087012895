import React from 'react'

const SuperFood = () => {
  return (
    <div className='product-container w-full h-full flex flex-col items-center justify-center'>
      <div className="welcome text-center">
          <h2>
            Super<span> Food</span>
          </h2>
          <p>
          Elevate your well-being with our pure, Ayurvedic treasures, meticulously crafted for holistic harmony.
          </p>
        </div>
        <div className='product-section w-full h-full'>
            <div className='products w-full h-full'>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://5.imimg.com/data5/JD/EG/MY/SELLER-61630555/sorghum-jowar--500x500.jpg" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>jowar</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYiiA5tlLop4uu8khN-_zSqATOjQhxwlXNuNQRIXwXiA&s" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Bajra</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://www.amar-khamar.com/cdn/shop/products/KodoMillet.jpg?v=1667939345&width=1445" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Kodo Millet</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://5.imimg.com/data5/SELLER/Default/2023/4/303689178/SN/JA/WM/86539219/ragi-seeds-finger-millet-.jpg" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Ragi</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://www.realfoodph.com/cdn/shop/products/20190528-230148-0000_1024x1024_88cbf001-a861-471b-891f-b4619a9efe06_1024x.jpg?v=1635228131" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Saba</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://5.imimg.com/data5/ANDROID/Default/2023/12/369623294/GK/NQ/KD/57664366/product-jpeg-500x500.jpg" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4>Kagni Millet</h4>
                    </div>
                </div>
                <div className='product'>
                    <div className='product-img'>
                        <img src="https://m.media-amazon.com/images/I/71oTiBTPdnL._AC_UF350,350_QL80_DpWeblab_.jpg" alt="spices" />
                    </div>
                    <div className='product-text'>
                        <h4> Kurmi Seeds</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SuperFood